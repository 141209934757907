(function ($, _, Bloodhound) {
    "use strict";

    function CustomerScheduleEditor(container, customerSchedule) {
        this.container = container;
        this.customerSchedule = customerSchedule;
        this.isMouseDown = false;
        this.isHighlighted = false;
        this.currentRow = null;
    }

    CustomerScheduleEditor.prototype.initialize = function () {
        this.container.on('mouseover', '.shed-vod-scheduler__customer-schedule-table-cell-value', this.onMouseOver.bind(this));
        this.container.on('mousedown', '.shed-vod-scheduler__customer-schedule-table-cell-value--editable', this.onMouseDown.bind(this));
        this.container.on("selectstart", '.shed-vod-scheduler__customer-schedule-table-cell-value--editable', function () {
            return false;
        });
        $(document).mouseup(this.onMouseUp.bind(this));

        var tables = this.container;
        $('tbody', tables[0]).on('scroll', function (e) {
            $('tbody', tables[1]).scrollTop($(this).scrollTop());
        });
        $('tbody', tables[1]).on('scroll', function (e) {
            $('tbody', tables[0]).scrollTop($(this).scrollTop());
        });
    };

    CustomerScheduleEditor.prototype.onMouseDown = function (event) {
        this.isMouseDown = true;
        this.currentRow = $(event.target).parent().data("broadcast-version-id");
        $(event.target).toggleClass("shed-vod-scheduler__customer-schedule-table-cell-value--highlighted");
        this.isHighlighted = $(event.target).hasClass("shed-vod-scheduler__customer-schedule-table-cell-value--highlighted");

        return false; // prevent text selection
    };

    CustomerScheduleEditor.prototype.onMouseUp = function () {
        if (this.isMouseDown) {
            this.customerSchedule.updateStats();
        }

        this.isMouseDown = false;
    };

    CustomerScheduleEditor.prototype.onMouseOver = function (event) {
        if (this.isMouseDown) {
            if (this.currentRow === $(event.target).parent().data("broadcast-version-id")) {
                $(event.target).toggleClass("shed-vod-scheduler__customer-schedule-table-cell-value--highlighted", this.isHighlighted);
            }
        }
    };


    function CustomerScheduleFiller(container, schedule, scheduler) {
        this.container = container;
        this.schedule = schedule;
        this.scheduler = scheduler;
    }

    CustomerScheduleFiller.prototype.initialize = function () {
        $('button', this.container).click(this.copySchedule.bind(this));

        return this;
    };

    CustomerScheduleFiller.prototype.copySchedule = function () {
        var sourceTitleTr,
            sourceCustomerId = $('select', this.container).val(),
            sourceCustomerSchedule = this.scheduler.getCustomerSchedule(sourceCustomerId),
            destinationCustomerSchedule = this.schedule,
            values = sourceCustomerSchedule.getValues(),
            sourceTitlesTable = $('.shed-vod-scheduler__customer-schedule-table--titles', sourceCustomerSchedule.container);

        _.forOwn(values, function (dates, broadcastVersionId) {
            if (dates.length > 0) {
                sourceTitleTr = $('tr[data-broadcast-version-id=' + broadcastVersionId + ']', sourceTitlesTable);

                var editLevel = sourceTitleTr.data('edit-level'),
                    rowData,
                    destinationTr;

                if (destinationCustomerSchedule.isEditLevelAllowed(editLevel)) {
                    rowData = {
                        id:            broadcastVersionId,
                        editLevel:     editLevel,
                        runtime:       sourceTitleTr.data('runtime'),
                        runtimePal:    sourceTitleTr.data('runtime-pal'),
                        title:         $('.shed-vod-scheduler__customer-schedule-table-cell-title span', sourceTitleTr).text(),
                        selectedDates: dates
                    };

                    if (!destinationCustomerSchedule.hasBroadcastVersion(broadcastVersionId)) {
                        destinationCustomerSchedule.addBroadcastVersion(rowData);
                    } else {
                        destinationTr = $('tr[data-broadcast-version-id=' + broadcastVersionId + ']', destinationCustomerSchedule.container);

                        destinationTr.remove();
                        destinationCustomerSchedule.addBroadcastVersion(rowData);
                    }
                }
            }
        });

        destinationCustomerSchedule.sort();
        destinationCustomerSchedule.updateStats();
    };


    function CustomerSchedule(container, scheduler) {
        this.scheduler = scheduler;
        this.container = container;
        this.customerId = container.data('customer-id');
        this.customerVideoStandard = container.data('customer-video-standard');
        this.dates = this.createDates(container);
        this.allowedEditLevels = container.data('customer-allowed-edit-levels').split(',');
        this.editor = new CustomerScheduleEditor($('table', container), this);
        this.filler = new CustomerScheduleFiller($('.shed-vod-scheduler__customer-schedule-fill-form', container), this, scheduler);
    }

    CustomerSchedule.prototype.createDates = function (container) {
        var dates = [];
        $('.shed-vod-scheduler__customer-schedule-table-cell-date', container).each(function (thIndex, thDom) {
            /*jslint unparam: true, node: true */
            dates.push($(thDom).data('date'));
        });

        return dates;
    };

    CustomerSchedule.prototype.initialize = function () {
        this.updateStats();
        this.editor.initialize();
        this.filler.initialize();
    };

    CustomerSchedule.prototype.hasBroadcastVersion = function (broadcastVersionId) {
        var matchingRowsCount = $('tr[data-broadcast-version-id=' + broadcastVersionId + ']', this.container).length;

        return matchingRowsCount > 0;
    };

    CustomerSchedule.prototype.isEditLevelAllowed = function (editLevel) {
        return this.allowedEditLevels.indexOf(editLevel) !== -1;
    };

    CustomerSchedule.prototype.addBroadcastVersion = function (data) {
        var titlesTable = $('.shed-vod-scheduler__customer-schedule-table--titles', this.container);
        var dataTable = $('.shed-vod-scheduler__customer-schedule-table--data', this.container);

        var i,
            isEditLevelAllowed = this.isEditLevelAllowed(data.editLevel),
            trClass = 'shed-vod-scheduler__customer-schedule-table-row-title',
            tdClass = 'shed-vod-scheduler__customer-schedule-table-cell-value shed-vod-scheduler__customer-schedule-table-cell-value--editable',
            editLevelSpanClass = 'default',
            languageSpanClass = 'primary',
            editLevelSpan = '',
            tr,
            tdHighlightedClass;

        if (!isEditLevelAllowed) {
            trClass = 'shed-vod-scheduler__customer-schedule-table-row-title shed-vod-scheduler__customer-schedule-table-row-title--disabled';
            tdClass = 'shed-vod-scheduler__customer-schedule-table-cell-value';
        }

        if (data.editLevel) {
            editLevelSpan = ' <span class="label label-' + editLevelSpanClass + '">' + data.editLevel + '</span>';
            if (data.language !== 'English') {
                editLevelSpan += ' <span class="label label-' + languageSpanClass + '">' + data.language + '</span>';
            }
        }

        tr = '<tr class="' + trClass + '" data-broadcast-version-id="' + data.id + '" data-runtime="' + data.runtime + '" data-runtime-pal="' + data.runtimePal + '" data-edit-level="' + data.editLevel + '">';

        var titleTr = tr + '<td class="shed-vod-scheduler__customer-schedule-table-cell-title"><span>' + data.title + '</span>' + editLevelSpan + '</td><td class="shed-vod-scheduler__customer-schedule-table-cell-range-length"></td></tr>';

        var dataTr = tr;
        for (i = 0; i < this.dates.length; i++) {
            tdHighlightedClass = '';
            if (isEditLevelAllowed && data.selectedDates && data.selectedDates.indexOf(this.dates[i]) !== -1) {
                tdHighlightedClass = ' shed-vod-scheduler__customer-schedule-table-cell-value--highlighted';
            }
            dataTr += '<td class="' + tdClass + tdHighlightedClass + '"></td>';
        }
        dataTr += '</tr>';

        $('tbody', titlesTable).prepend(titleTr);
        $('tbody', dataTable).prepend(dataTr);
    };

    CustomerSchedule.prototype.updateStats = function () {
        // Update selected range length column
        var titlesTable = $('.shed-vod-scheduler__customer-schedule-table--titles', this.container);
        var dataTable = $('.shed-vod-scheduler__customer-schedule-table--data', this.container);

        $('.shed-vod-scheduler__customer-schedule-table-row-title', titlesTable).each(function (trIndex, trDom) {
            /*jslint unparam: true, node: true */
            var titleTr = $(trDom),
                dataTr = $('tbody tr', dataTable)[trIndex],
                rangeLength = $('.shed-vod-scheduler__customer-schedule-table-cell-value--highlighted', dataTr).length;

            $('.shed-vod-scheduler__customer-schedule-table-cell-range-length', titleTr).text(rangeLength > 0 ? rangeLength : '');
        });

        // Update runtime length row
        $('.shed-vod-scheduler__customer-schedule-table-cell-runtime-total', dataTable).each(this.updateRuntime.bind(this));
    };

    CustomerSchedule.prototype.updateRuntime = function (index, runTimeCell) {
        var tdValueChildOffset = 1, // child numerations starts from 1
            totalRuntime = 0,
            titlesCount = 0,
            totalRuntimeText = '',
            customerVideoStandard = this.customerVideoStandard;

        $('.shed-vod-scheduler__customer-schedule-table-row-title', this.container).each(function (trIndex, trValue) {
            /*jslint unparam: true, node: true */
            var trRuntime;
            if (customerVideoStandard === 'NTSC') {
                trRuntime = $(trValue).data('runtime');
            } else {
                trRuntime = $(trValue).data('runtime-pal');
            }
            if ($('td:nth-child(' + (index + tdValueChildOffset) + ')', trValue).hasClass('shed-vod-scheduler__customer-schedule-table-cell-value--highlighted')) {
                titlesCount++;
                totalRuntime += trRuntime;
            }
        });

        if (titlesCount) {
            var suffix = titlesCount > 1 ? 's' : '';
            totalRuntimeText = titlesCount + ' title' + suffix + ' / ';
            var hours = "0" + parseInt(totalRuntime / 3600, 10);
            var minutes = "0" + parseInt(totalRuntime % 3600 / 60, 10);
            var seconds = totalRuntime % 60;

            totalRuntimeText += ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
        }
        $(runTimeCell).html('<div><span>' + totalRuntimeText + '</span></div>');
    };

    CustomerSchedule.prototype.getValues = function () {
        var dataTable = $('.shed-vod-scheduler__customer-schedule-table--data', this.container);

        var values = {};
        var dates = this.dates;

        $('.shed-vod-scheduler__customer-schedule-table-row-title', dataTable).each(function (trIndex, trDom) {
            /*jslint unparam: true, node: true */
            var tr = $(trDom);

            var broadcastVersionId = tr.data('broadcast-version-id');
            values[broadcastVersionId] = [];

            var i = 0;
            $('.shed-vod-scheduler__customer-schedule-table-cell-value', tr).each(function (tdIndex, tdDom) {
                if ($(tdDom).hasClass('shed-vod-scheduler__customer-schedule-table-cell-value--highlighted')) {
                    values[broadcastVersionId].push(dates[i]);
                }
                i++;
            });
        });

        return values;
    };

    CustomerSchedule.prototype.sort = function () {
        var titlesTable = $('.shed-vod-scheduler__customer-schedule-table--titles', this.container);
        var dataTable = $('.shed-vod-scheduler__customer-schedule-table--data', this.container);

        var bvsIdsToFirstSelectedDay = {};

        $('tbody tr', dataTable).each(function (trIndex, trDom) {
            var tr = $(trDom);

            var bvId = tr.data('broadcast-version-id');

            bvsIdsToFirstSelectedDay[bvId] = $('.shed-vod-scheduler__customer-schedule-table-cell-value--highlighted:first', tr).index();
        });

        var titlesTrs = $('tbody tr', titlesTable);
        titlesTrs.sort(function (a, b) {
            var aTr = $(a);
            var aBvId = aTr.data('broadcast-version-id');

            var bTr = $(b);
            var bBvId = bTr.data('broadcast-version-id');

            var aFirstDateIndex = bvsIdsToFirstSelectedDay[aBvId];
            var bFirstDateIndex = bvsIdsToFirstSelectedDay[bBvId];

            if (aFirstDateIndex > bFirstDateIndex) {
                return 1;
            }

            if (aFirstDateIndex < bFirstDateIndex) {
                return -1;
            }

            var aTitle = $('.shed-vod-scheduler__customer-schedule-table-cell-title', aTr).text();
            var bTitle = $('.shed-vod-scheduler__customer-schedule-table-cell-title', bTr).text();

            if (aTitle > bTitle) {
                return 1;
            }

            if (aTitle < bTitle) {
                return -1;
            }

            return 0;
        });

        titlesTrs.detach().prependTo($('tbody', titlesTable));

        var bvsIdsSorted = [];
        titlesTrs.each(function (i, trDom) {
            bvsIdsSorted.push($(trDom).data('broadcast-version-id'));
        });

        var dataTrs = $('tbody tr', dataTable);
        dataTrs.sort(function (a, b) {
            var aTr = $(a);
            var aBvId = aTr.data('broadcast-version-id');

            var bTr = $(b);
            var bBvId = bTr.data('broadcast-version-id');

            var aFirstDateIndex = bvsIdsSorted.indexOf(aBvId);
            var bFirstDateIndex = bvsIdsSorted.indexOf(bBvId);

            if (aFirstDateIndex > bFirstDateIndex) {
                return 1;
            }

            if (aFirstDateIndex < bFirstDateIndex) {
                return -1;
            }

            return 0;
        });

        dataTrs.detach().prependTo($('tbody', dataTable));
    };


    function Scheduler(container) {
        this.container = container;
        this.searchInput = $('.typeahead', this.container);
        this.saveButton = $('.j-save-schedule-button', this.container);
        this.customerSchedules = this.createCustomerSchedules(container);
    }

    Scheduler.prototype.initialize = function () {
        this.initSearch();
        _.forOwn(this.customerSchedules, function (customerSchedule) {
            customerSchedule.initialize();
        });

        this.saveButton.click(this.save.bind(this));
    };

    Scheduler.prototype.createCustomerSchedules = function (container) {
        var customerSchedules = {};
        $('.shed-vod-scheduler__customer-schedule', container).each(_.bind(function (index, customerScheduleDom) {
            /*jslint unparam: true, node: true */

            var customerSchedule = new CustomerSchedule($(customerScheduleDom), this);
            customerSchedules[customerSchedule.customerId] = customerSchedule;
        }, this));

        return customerSchedules;
    };

    Scheduler.prototype.getCustomerSchedule = function (customerId) {
        return this.customerSchedules[customerId];
    };

    Scheduler.prototype.initSearch = function () {
        var broadcastVersions = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote:         {
                url:      this.searchInput.data('url') + '?query=%QUERY',
                wildcard: '%QUERY'
            }
        });

        broadcastVersions.initialize();

        this.searchInput.typeahead(null, {
            display:   function (searchObject) {
                var titleDescription = '';
                if (searchObject.dataType === 'BroadcastVersion') {
                    titleDescription += 'BV / ' + searchObject.editLevel + ' / ' + searchObject.language;
                } else if (searchObject.dataType === 'VodPackage') {
                    titleDescription += 'Package / ' + searchObject.editLevels.join(', ') + ' / ' + searchObject.languages.join(', ');
                }

                return searchObject.title + ' (' + titleDescription + ')';
            },
            highlight: true,
            source:    broadcastVersions,
            limit:     25
        }).bind("typeahead:selected", this.onSearchSelect.bind(this));
    };

    Scheduler.prototype.onSearchSelect = function (obj, data) {
        /*jslint unparam: true, node: true */

        this.searchInput.typeahead('val', '');

        if (data.dataType === 'BroadcastVersion') {
            this.addBroadcastVersion(data);
        } else {
            $.get(this.searchInput.data('package-content-url'), {vodPackageId: data.id}, _.bind(function (result) {
                result.forEach(_.bind(function (data) {
                    this.addBroadcastVersion(data);
                }, this));
            }, this));
        }

        return false;
    };

    Scheduler.prototype.addBroadcastVersion = function (data) {
        _.forOwn(this.customerSchedules, function (customerSchedule) {
            if (!customerSchedule.hasBroadcastVersion(data.id)) {
                customerSchedule.addBroadcastVersion(data);
            }
        });
    };

    Scheduler.prototype.save = function () {
        var results = {},
            postUrl = this.saveButton.data('url'),
            fromDate = this.saveButton.data('from-date'),
            toDate = this.saveButton.data('to-date'),
            redirectUrl = this.saveButton.data('redirect-url');

        _.forOwn(this.customerSchedules, function (customerSchedule) {
            results[customerSchedule.customerId] = customerSchedule.getValues();
        });

        $('.shed-vod-scheduler__error').hide();

        $.post(postUrl, {data: JSON.stringify(results), fromDate: fromDate, toDate: toDate})
            .done(function (data) {
                if (data.status !== 'success') {
                    $('.shed-vod-scheduler__error').text(data.message).show();
                } else {
                    window.location.href = redirectUrl;
                }
            });

        return false;
    };

    var ScheduleXmlPreview = function (container) {
        this.container = container;
    };

    ScheduleXmlPreview.prototype.initialize = function () {
        $('.shed-vod-scheduler-xml-preview__check-all').click(_.bind(function (event) {
            $('tbody :checkbox', this.container).prop('checked', event.target.checked);
        }, this));

        /**
         * Disable download button unless some option is selected
         */
        var previewTable = $('.shed-vod-scheduler-xml-preview table');
        $('tr > :nth-child(1) :checkbox', previewTable).click(function (event) {
            var checkedOptionsCount = $('tbody tr > :nth-child(1) :checkbox:checked').length;
            if (checkedOptionsCount > 0) {
                $('.shed-vod-scheduler-xml-preview__download-btn').removeClass('disabled');
            } else {
                $('.shed-vod-scheduler-xml-preview__download-btn').addClass('disabled');
            }
        });
    };

    $(function () {
        var scheduler = new Scheduler($('.shed-vod-scheduler'));
        scheduler.initialize();

        var scheduleXmlPreview = new ScheduleXmlPreview($('.shed-vod-scheduler-xml-preview'));
        scheduleXmlPreview.initialize();
    });

}($, _, window.Bloodhound));
