(function (window, $, d3, Bloodhound) {
    "use strict";

    function RevenueImport(container) {
        this.container = container;
        this.customerTemplatesUrl = this.container.data('customer-templates-url');
        this.linearChannelRevenueTypeId = this.container.data('linear-channel-revenue-type-id');
        this.svodRevenueTypeId = this.container.data('svod-revenue-type-id');
    }

    RevenueImport.prototype.initialize = function () {
        var customerSelect = $('select[name="revenue_import[customer]"]', this.container);

        this.updateCustomerTemplatesByCustomerId(customerSelect.val());
        customerSelect.change(_.bind(function (event) {
            this.updateCustomerTemplatesByCustomerId(event.target.value);
        }, this));

        this.visibilityOptions = {
            'revenue_import[reportDate][month]': function (formData) {
                return formData['revenue_import[currency]'] !== 'USD';
            },
            'revenue_import[amount]':            _.bind(function (formData) {
                return Number(formData['revenue_import[revenueType]']) === this.linearChannelRevenueTypeId ||
                    Number(formData['revenue_import[revenueType]']) === this.svodRevenueTypeId;
            }, this),
            'revenue_import[template]':          _.bind(function (formData) {
                return Number(formData['revenue_import[revenueType]']) !== this.linearChannelRevenueTypeId &&
                    Number(formData['revenue_import[revenueType]']) !== this.svodRevenueTypeId;
            }, this),
            'revenue_import[dataTypeField]':     _.bind(function (formData) {
                return Number(formData['revenue_import[revenueType]']) !== this.linearChannelRevenueTypeId &&
                    Number(formData['revenue_import[revenueType]']) !== this.svodRevenueTypeId;
            }, this),
            'revenue_import[report]':            _.bind(function (formData) {
                return Number(formData['revenue_import[revenueType]']) !== this.linearChannelRevenueTypeId &&
                    Number(formData['revenue_import[revenueType]']) !== this.svodRevenueTypeId;
            }, this),
            'revenue_import[lookupField]':       _.bind(function (formData) {
                return Number(formData['revenue_import[revenueType]']) !== this.linearChannelRevenueTypeId &&
                    Number(formData['revenue_import[revenueType]']) !== this.svodRevenueTypeId;
            }, this)
        };

        this.updateFormElementsVisibility();
        this.updateTemplateRequired();
        $('input, select', this.container).change(_.bind(function () {
            this.updateFormElementsVisibility();
            this.updateTemplateRequired();
        }, this));
    };

    RevenueImport.prototype.updateTemplateRequired = function () {
        $('[name="revenue_import[template]"]', this.container).prop(
            'required',
            this.isElementVisible('revenue_import[template]')
        );
    };

    RevenueImport.prototype.getFormData = function () {
        var formData = {};
        this.container.serializeArray().forEach(function (field) {
            formData[field.name] = field.value;
        });

        return formData;
    };

    RevenueImport.prototype.isElementVisible = function (elementName) {
        var element = $('[name="' + elementName + '"]', this.container).closest('.form-group');

        return element.is(':visible');
    };

    RevenueImport.prototype.changeElementVisibility = function (elementName, isVisible) {
        var element = $('[name="' + elementName + '"]', this.container).closest('.form-group');
        if (isVisible) {
            element.show();
        } else {
            element.hide();
        }
    };

    RevenueImport.prototype.updateFormElementsVisibility = function () {
        var formData = this.getFormData();
        _.forEach(this.visibilityOptions, _.bind(function (visibilityResolver, elementName) {
            var isVisible = visibilityResolver(formData);
            this.changeElementVisibility(elementName, isVisible);
        }, this));
    };

    RevenueImport.prototype.updateCustomerTemplatesByCustomerId = function (customerId) {
        if (!customerId) {
            this.fillCustomerTemplates([]);

            return false;
        }

        $.get(this.customerTemplatesUrl, {customerId: customerId}, this.fillCustomerTemplates);
    };

    RevenueImport.prototype.fillCustomerTemplates = function (customerTemplates) {
        var templateSelect = $('select[name="revenue_import[template]"]', this.container);

        templateSelect.html('');
        _.forEach(customerTemplates, function (customerTemplate) {
            templateSelect.append('<option value="' + customerTemplate.id + '">' + customerTemplate.name + '</option>');
        });
    };


    function RevenueImportPreview(container) {
        this.container = container;
        this.searchUrl = this.container.data('search-url');
    }

    RevenueImportPreview.prototype.initialize = function () {
        $('.shed-revenue-import-preview__search-title-trigger', this.container).click(_.bind(this.showSearchControl, this));
        $('.shed-revenue-import-preview__possible-matches-select', this.container).change(_.bind(function (event) {
            var select = $(event.target),
                option = $('option:selected', select),
                data = {
                    id:    select.val(),
                    title: option.data('title'),
                    url:   option.data('url')
                },
                rowContainer = $(event.target).parents('tr').first();

            this.setMatchedTitle(data, rowContainer);
        }, this));
    };

    RevenueImportPreview.prototype.showSearchControl = function (event) {
        var trigger = $(event.target),
            rowContainer = trigger.parents('tr').first(),
            searchControl = $('<input name="search" class="form-control typeahead j-search-control" type="text" value="">'),
            bloodhound = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote:         {
                    url:      this.searchUrl + '?query=%QUERY',
                    wildcard: '%QUERY'
                }
            });

        trigger.after(searchControl);
        bloodhound.initialize();

        searchControl.typeahead(null, {
            display:   function (searchObject) {
                var titleDescription = '';
                if (searchObject.dataType === 'BroadcastVersion') {
                    titleDescription += ' (' + searchObject.editLevel + ' / ' + searchObject.language + ')';
                }

                return searchObject.title + titleDescription;
            },
            highlight: true,
            source:    bloodhound,
            limit:     25
        }).bind("typeahead:selected", _.bind(function (obj, data) {
            /*jslint unparam: true, node: true */
            this.setMatchedTitle(data, rowContainer);

            searchControl.typeahead('destroy');
            searchControl.remove();
            trigger.show();

            return false;
        }, this));

        searchControl.focus();
        trigger.hide();

        return false;
    };

    RevenueImportPreview.prototype.setMatchedTitle = function (data, rowContainer) {
        var titleLink = $('<a></a>').text(data.title).attr('href', data.url);

        var editLevel = '';
        if (data.editLevel) {
            editLevel = $('<small class="label label-primary"></small>').text(data.editLevel);
        }

        var language = '';
        if (data.language && data.language !== 'English') {
            language = $('<small class="label label-primary"></small>').text(data.language);
        }

        $('.shed-revenue-import-preview__matched-title', rowContainer).html('').append(titleLink).append(' ').append(editLevel).append(' ').append(language);

        $('input[name="dataIds[]"]', rowContainer).val(data.id);
    };


    function RevenueDirectorShareSwitcher(container) {
        this.container = container;
    }

    RevenueDirectorShareSwitcher.prototype.initialize = function () {
        $('input[name="switch"]', this.container).change(_.bind(this.toggleContent, this));

        this.toggleContent();
    };

    RevenueDirectorShareSwitcher.prototype.toggleContent = function () {
        var enabledValue = $('input[name="switch"]:checked', this.container).val();
        var disabledValue = enabledValue === 'director' ? 'total' : 'director';

        this.container.addClass('shed-revenue-director-share-switcher--' + enabledValue);
        this.container.removeClass('shed-revenue-director-share-switcher--' + disabledValue);
    };


    function RevenueGraph(container, data) {
        this.container = container;
        this.data = data;

        this.margin = {top: 20, right: 95, bottom: 48, left: 50};
        this.containerWidth = this.container.width();
        this.containerHeight = this.container.height();
        this.width = this.containerWidth - this.margin.left - this.margin.right;
        this.height = this.containerHeight - this.margin.top - this.margin.bottom;
    }

    RevenueGraph.prototype.initialize = function () {
        this.parseDataDates();

        var revenueTypesNames = this.getRevenueTypesNames();
        var marginDates = this.getMarginDates();

        /**
         * Color scale for revenue types
         */
        var color = d3.scale.category10()
            .domain(revenueTypesNames);

        /**
         * x axis
         */
        var x = d3.time.scale()
            .range([0, this.width])
            .domain(marginDates);

        var xAxis = d3.svg.axis()
            .scale(x)
            .orient("bottom")
            .ticks(d3.time.month);

        /**
         * y axis
         */
        var nest = function (positive) {
            return d3.nest()
                .key(function (d) {
                    return d.date;
                })
                .rollup(function (leaves) {
                    return d3.sum(leaves, function (leaf) {
                        var value = parseFloat(leaf.amount);

                        if (positive && value > 0 || !positive && value < 0) {
                            return value;
                        }

                        return 0;
                    });
                });
        };

        var amountPositiveByDate = nest(true).entries(this.data);
        var amountNegativeByDate = nest(false).entries(this.data);

        var y = d3.scale.linear()
            .range([this.height, 0])
            .domain([
                d3.min(amountNegativeByDate, function (d) {
                    return d.values;
                }),
                d3.max(amountPositiveByDate, function (d) {
                    return d.values;
                })
            ]);

        var yAxis = d3.svg.axis()
            .scale(y)
            .orient("left")
            .tickFormat(function (d) {
                var f = d3.format("s");
                return '$' + f(d);
            });

        /**
         * Area and stack
         */
        var area = d3.svg.area()
            .x(function (d) {
                return x(d.date);
            })
            .y0(function (d) {
                return y(d.y0);
            })
            .y1(function (d) {
                return y(d.y0 + d.y);
            });

        var datesOnAxis = d3.time.months(marginDates[0], d3.time.month.offset(marginDates[1], +1));

        var revenueTypesStackPositive = this.getStack(datesOnAxis, true);
        var revenueTypesStackNegative = this.getStack(datesOnAxis, false);
        /**
         * Build svg graph
         */
        var svg = d3.select(this.container.toArray()[0])
            .append("svg")
            .attr("width", this.containerWidth)
            .attr("height", this.containerHeight)
            .append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

        var revenueType = svg.selectAll(".revenueTypePositive")
            .data(revenueTypesStackPositive)
            .enter().append("g")
            .attr("class", "revenueTypePositive");

        revenueType.append('path')
            .attr('class', 'area')
            .attr("d", function (d) {
                return area(d.values);
            })
            .style("fill", function (d) {
                return color(d.name);
            });

        revenueType = svg.selectAll(".revenueTypeNegative")
            .data(revenueTypesStackNegative)
            .enter().append("g")
            .attr("class", "revenueTypeNegative");

        revenueType.append('path')
            .attr('class', 'area')
            .attr("d", function (d) {
                return area(d.values);
            })
            .style("fill", function (d) {
                return color(d.name);
            });

        svg.append("g")
            .attr("class", "shed-revenue-graph__svg-axis shed-revenue-graph__svg-axis--x")
            .attr("transform", "translate(0," + y(0) + ")")
            .call(xAxis)
            .selectAll("text")
            .attr('class', 'shed-revenue-graph__svg-axis-label--font-small')
            .attr("y", 0)
            .attr("x", 9)
            .attr("dy", ".35em")
            .attr("transform", "rotate(45)")
            .style("text-anchor", "start");

        svg.append("g")
            .attr("class", "shed-revenue-graph__svg-axis shed-revenue-graph__svg-axis--y")
            .call(yAxis);

        var legend = svg.selectAll(".legend")
            .data(color.domain().slice().reverse())
            .enter().append("g")
            .attr("class", "legend")
            .attr("transform", function (d, i) {
                return "translate(95," + i * 20 + ")";
            });

        legend.append("rect")
            .attr("x", this.width - 18)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", color);

        legend.append("text")
            .attr("x", this.width - 24)
            .attr("y", 9)
            .attr("dy", ".35em")
            .style("text-anchor", "end")
            .text(function (d) {
                return d;
            });
    };

    RevenueGraph.prototype.getStack = function (datesOnAxis, positive) {
        var stack = d3.layout.stack()
            .values(function (d) {
                return d.values;
            });

        var stackGroups = {};
        this.data.filter(function (d) {
            return positive ? d.amount >= 0 : d.amount <= 0;
        }).forEach(function (d) {
            if (!stackGroups[d.revenueType]) {
                stackGroups[d.revenueType] = {name: d.revenueType, values: {}};
                datesOnAxis.forEach(function (dateOnAxis) {
                    stackGroups[d.revenueType].values[dateOnAxis.getFullYear() + '-' + dateOnAxis.getMonth() + '-' + dateOnAxis.getDate()] = {
                        date: dateOnAxis,
                        y:    0
                    };
                });
            }
            stackGroups[d.revenueType].values[d.date.getFullYear() + '-' + d.date.getMonth() + '-' + d.date.getDate()].y += parseFloat(d.amount);
        });

        stackGroups = d3.values(stackGroups);
        stackGroups.forEach(function (d) {
            d.values = d3.values(d.values);
        });

        return stack(stackGroups);
    };

    RevenueGraph.prototype.getMarginDates = function () {
        var realMarginDates = d3.extent(this.data, function (d) {
            return d.date;
        });

        return [realMarginDates[0], d3.time.month.offset(realMarginDates[1], +1)];
    };

    RevenueGraph.prototype.getRevenueTypesNames = function () {
        return this.data.map(function (d) {
            return d.revenueType;
        }).filter(function (value, index, self) {
            return self.indexOf(value) === index;
        });
    };

    RevenueGraph.prototype.parseDataDates = function () {
        var parseDate = d3.time.format('%Y-%m-%d').parse;

        this.data.forEach(function (d) {
            d.date = parseDate(d.date);
        });
    };


    $(function () {
        var revenueImportContainer = $('.shed-revenue-upload-form');
        if (revenueImportContainer.length > 0) {
            var revenueImport = new RevenueImport(revenueImportContainer);
            revenueImport.initialize();
        }

        var revenueImportPreviewContainer = $('.shed-revenue-import-preview');
        if (revenueImportPreviewContainer.length > 0) {
            var revenueImportPreview = new RevenueImportPreview(revenueImportPreviewContainer);
            revenueImportPreview.initialize();
        }

        var revenueSwitcherContainer = $('.shed-revenue-director-share-switcher');
        if (revenueSwitcherContainer.length > 0) {
            var revenueSwitcher = new RevenueDirectorShareSwitcher(revenueSwitcherContainer);
            revenueSwitcher.initialize();
        }

        var revenueGraphContainer = $('.shed-revenue-graph');
        if (revenueGraphContainer.length > 0) {
            var dataVar = revenueGraphContainer.data('data-var');
            var data = window[dataVar];
            var revenueGraph = new RevenueGraph(revenueGraphContainer, data);
            revenueGraph.initialize();
        }
    });
}(window, $, window.d3, window.Bloodhound));
