(function (window, $) {
    "use strict";

    function BroadcastVersionForm(container) {
        this.container = container;
        this.currentBillingId = undefined;
    }

    BroadcastVersionForm.prototype.initialize = function () {
        this.initMovieScenes();
        this.initCopyMovieSummary255Button();
        this.initGenerateBillingIdButtons();
    };

    BroadcastVersionForm.prototype.initGenerateBillingIdButtons = function () {
        $('.shed-bv-form__generate-billing-id-trigger', this.container).click(_.bind(function (event) {
            var button = $(event.target);
            var url = button.data('url');

            var data = this.currentBillingId ? {currentBillingId: this.currentBillingId} : {};

            $.get(url, data, _.bind(function (data) {
                button.prev().val(data.billingId);
                this.currentBillingId = data.billingId;
            }, this));
        }, this));
    };

    BroadcastVersionForm.prototype.initCopyMovieSummary255Button = function () {
        var textarea = $('.shed-bv-form__summary-255', this.container);

        textarea.siblings('.shed-bv-form__summary-255-movie-copy-trigger').click(function () {
            textarea.val(textarea.data('movie-value'));
        });
    };

    BroadcastVersionForm.prototype.initMovieScenes = function () {
        var movieScenesSelector = $('#broadcast_version_movieScenesIds', this.container);
        if (movieScenesSelector.length > 0) {
            var tbody = $('tbody', movieScenesSelector);

            var updateView = function () {
                var checkboxWrappers = $('tr', tbody);

                checkboxWrappers = _.sortBy(checkboxWrappers, function (item) {
                    return $('input:checked', $(item)).length !== 1;
                });

                $('input:checked', checkboxWrappers).each(function (k, v) {
                    $(v).parents().eq(3).addClass('active');
                });
                $('input:not(:checked)', checkboxWrappers).each(function (k, v) {
                    $(v).parents().eq(3).removeClass('active');
                });

                tbody.html(checkboxWrappers);
            };

            $(movieScenesSelector).on('change', 'input', function () {
                updateView();
            });

            tbody
                .sortable({
                    update: function () {
                        updateView();
                    }
                })
                .disableSelection();

            updateView();
        }
    };

    $(function () {
        var container = $('form[name="broadcast_version"]');
        if (container.length > 0) {
            var bvForm = new BroadcastVersionForm(container);
            bvForm.initialize();
        }
    });

}(window, $));