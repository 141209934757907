(function (window, $) {
    "use strict";

    function ContentList(container) {
        this.container = container;
        this.url = container.data('url');
    }

    ContentList.prototype.initialize = function () {
        this.attachEventHandlers(this.container);

        //noinspection SpellCheckingInspection
        window.addEventListener('popstate', _.bind(function () {
            this.reload(location.href);
        }, this));
    };

    ContentList.prototype.attachEventHandlers = function (container) {
        var reloadByLink = _.bind(function (event) {
                this.reload(event.target.href, false, true);

                return false;
            }, this),
            reloadByFilter = _.bind(function (event) {
                this.reload(this.url, true, true, event.target);

                return false;
            }, this),
            delay = (function () {
                var timer = 0;
                return function (callback, ms) {
                    clearTimeout(timer);
                    timer = setTimeout(callback, ms);
                };
            }());

        $('.shed-content-list__filter:not(".shed-content-list__filter--autocomplete"):not(".shed-content-list__filter--date-picker")', container).change(reloadByFilter);
        $('.shed-content-list__filter--autocomplete', container).keyup(function (event) {
            delay(function () {
                reloadByFilter(event);
            }, 250);
        });
        $('.shed-content-list__filter--date-picker', container).datepicker({keepEmptyValues: true}).on('changeDate', function (event) {
            $(event.target).datepicker('hide');
            reloadByFilter(event);
        });
        $('th a', container).click(reloadByLink);
        $('.pagination a', container).click(reloadByLink);
    };

    ContentList.prototype.reload = function (url, addParams, addToHistory, ignoreElementUpdate) {
        var params = addParams ? this.getParams() : {},
            separator = (url.indexOf('?') > -1) ? '&' : '?',
            requestUrl;

        if (addToHistory) {
            history.pushState(null, '', url + separator + $.param(params));
        }

        params._ajax = 1;
        requestUrl = url + separator + $.param(params);

        var contentListData = $('.shed-content-list__data', this.container);

        contentListData.isLoading({
            text:     "Refreshing data...",
            position: "overlay",
            tpl:      '<span class="isloading-wrapper %wrapper%">%text%<i class="%class% glyphicon glyphicon-refresh spin"></i></span>'
        });

        $.ajax({
            dataType: "html",
            url:      requestUrl,
            success:  _.bind(function (data) {
                contentListData.isLoading('hide');
                contentListData.html(data);
                this.setParams(this.getParamsFromUrl(requestUrl), ignoreElementUpdate);
                this.attachEventHandlers(contentListData);
            }, this)
        });
    };

    ContentList.prototype.getParams = function () {
        var params = {};

        $('.shed-content-list__filter', this.container).each(function (index, element) {
            /*jslint unparam: true, node: true */
            var filter = $(element);
            params[filter.attr('name')] = filter.val();
        });

        return params;
    };

    ContentList.prototype.setParams = function (params, ignoreElementUpdate) {
        $('.shed-content-list__filter', this.container).each(function (index, element) {
            /*jslint unparam: true, node: true */

            if (ignoreElementUpdate === element) {
                return;
            }

            var filter = $(element),
                filterName = filter.attr('name');

            if (params[filterName] !== undefined) {
                filter.val(params[filterName]);
            } else {
                if (filter.prop('tagName') === 'SELECT') {
                    filter.val($("option:first", filter).val());
                } else {
                    filter.val('');
                }
            }
        });

        return params;
    };

    ContentList.prototype.getParamsFromUrl = function (url) {
        url = url.split('?')[1];
        if (!url) {
            return {};
        }

        var urlParts = url.split('&'),
            params = {},
            i,
            p;

        for (i = 0; i < urlParts.length; ++i) {
            p = urlParts[i].split('=', 2);
            if (p.length === 1) {
                params[p[0]] = "";
            } else {
                params[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
            }
        }

        return params;
    };

    $(function () {
        var contentListContainer = $('.shed-content-list');
        if (contentListContainer.length > 0) {
            var contentList = new ContentList(contentListContainer);
            contentList.initialize();
        }
    });
}(window, $));
