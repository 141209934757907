$(document).ready(function () {
    "use strict";

    $("#scene_contentTags").select2({
        tags: true
    });

    $('.shed-movie-form__generate-dvd-movie-id-trigger').click(function () {
        var url = $(this).data('url');

        $.get(url, function (data) {
            $('#movie_dvdMovieId').val(data.dvdMovieId);
            $('#movie_upc').val(data.upc);
            $('#movie_iteration').val(data.iteration);
        });

        return false;
    });

    $('.shed-input--character-countable').each(function (key, inputHtml) {
        /*jslint unparam: true, node: true */

        var element = $(inputHtml);

        element.characterCounter({
            limit:                   element.data('max-length'),
            counterFormat:           '%1 characters left',
            counterCssClass:         'shed-character-counter',
            counterExceededCssClass: 'shed-character-counter--exceeded'
        });
    });

    $('form.shed-form--set-focus-on-first-field:first *:input[type!=hidden]:first').focus();

    $('form.shed-form--prevent-double-submit').on('submit', function (e) {
        var $form = $(this);

        if ($form.data('submitted') === true) {
            e.preventDefault();
        } else {
            $form.data('submitted', true);
        }
    });

    $('form.shed-form label[data-description]').each(function (key, label) {
        var labelJquery = $(label);
        var description = labelJquery.data('description');

        if (description) {
            labelJquery.closest('.form-group').after('<div class="shed-form__description alert alert-warning">' + description + '</div>');
        }
    });

    $('.input-daterange').datepicker({
        autoclose:   true,
        orientation: "bottom"
    }).on('hide', function (event) {
        var target = $(event.target);
        if (target.data('date-picker-to') === undefined) {
            $('input[data-date-picker-to]', target.closest('.input-daterange')).datepicker('show');
        }
    });

    $('.shed-secondary-nav--client-navigation .shed-secondary-nav__item').click(function (e) {
        var clickedItem = $(e.target);
        var target = $(clickedItem.data('target'));
        var nav = clickedItem.closest('.shed-secondary-nav');

        $('.shed-secondary-nav__item', nav).each(function (key, itemDom) {
            var item = $(itemDom);
            var target = $(item.data('target'));

            item.removeClass('shed-secondary-nav__item--active');
            target.hide();
        });

        clickedItem.addClass('shed-secondary-nav__item--active');
        target.show();
    });

    $('.shed-form-entity-autocomplete').each(function (key, searchInput) {
        searchInput = $(searchInput);
        var valueInput = $('.shed-form-entity-autocomplete-value', searchInput.parent());

        var remoteUrl = searchInput.data('url');
        var searchSource = makeAutocompleteSearchSource(remoteUrl);

        var displayFields = searchInput.data('display-fields').split(',');
        initTypeAhead(valueInput, searchInput, searchSource, displayFields);

        function makeAutocompleteSearchSource(remoteUrl) {
            var searchSource = new window.Bloodhound({
                datumTokenizer: window.Bloodhound.tokenizers.obj.whitespace('title'),
                queryTokenizer: window.Bloodhound.tokenizers.whitespace,
                remote:         {
                    url:      remoteUrl + '?query=%QUERY',
                    wildcard: '%QUERY'
                }
            });
            searchSource.initialize();

            return searchSource;
        }

        function initTypeAhead(valueInput, searchInput, searchSource, displayFields) {
            searchInput.typeahead(null, {
                display:   function (searchObject) {
                    return displayFields.map(function (displayField) {
                        return searchObject[displayField];
                    }).join(' / ');
                },
                highlight: true,
                source:    searchSource,
                limit:     25
            }).bind("typeahead:selected", function (obj, data) {
                valueInput.val(data.id);
                return false;
            });
        }
    });
});