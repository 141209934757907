(function ($, _, Bloodhound) {
    "use strict";

    function VodPackageItems(container) {
        this.container = container;
        this.searchInput = $('.typeahead', this.container);
    }

    VodPackageItems.prototype.initialize = function () {
        this.initSearch();
    };

    VodPackageItems.prototype.initSearch = function () {
        var packageItems = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote:         {
                url:      this.searchInput.data('url') + '?query=%QUERY',
                wildcard: '%QUERY'
            }
        });

        packageItems.initialize();

        this.searchInput.typeahead(null, {
            display:   function (searchObject) {
                var titleDescription = '';
                if (searchObject.dataType === 'BroadcastVersion') {
                    titleDescription += 'BV / ' + searchObject.editLevel + ' / ' + searchObject.language;
                } else if (searchObject.dataType === 'VodPackage') {
                    titleDescription += 'Package / ' + searchObject.editLevels.join(', ');
                }

                return searchObject.title + ' (' + titleDescription + ')';
            },
            highlight: true,
            source:    packageItems,
            limit:     25
        }).bind("typeahead:selected", this.onSearchSelect.bind(this));
    };

    VodPackageItems.prototype.onSearchSelect = function (obj, datum) {
        /*jslint unparam: true, node: true */

        this.searchInput.typeahead('val', '');
        this.addItem(datum.dataType, datum.id);
    };

    VodPackageItems.prototype.addItem = function (dataType, dataId) {
        var newItemUrl = this.container.data('new-item-url');

        $.post(newItemUrl, {dataType: dataType, dataId: dataId})
            .done(_.bind(function (data) {
                $('.shed-vod-package-items__data', this.container).html(data);
            }, this));
    };


    function VodPackagesTree(container) {
        this.container = container;
    }

    VodPackagesTree.prototype.initialize = function () {
        $('.shed-vod-packages-tree__item-toggle-trigger').click(function (event) {
            var toggleTrigger = $(event.target);
            var treeItem = toggleTrigger.closest('.shed-vod-packages-tree__item');

            treeItem.toggleClass('shed-vod-packages-tree__item--expanded shed-vod-packages-tree__item--collapsed');
        });
    };

    $(function () {
        var vodPackageItemsContainer = $('.shed-vod-package-items');
        if (vodPackageItemsContainer.length > 0) {
            var vodPackageItems = new VodPackageItems(vodPackageItemsContainer);
            vodPackageItems.initialize();
        }

        var vodPackageTreeContainer = $('.shed-vod-packages-tree');
        if (vodPackageTreeContainer.length > 0) {
            var vodPackageTree = new VodPackagesTree(vodPackageTreeContainer);
            vodPackageTree.initialize();
        }
    });

}($, _, window.Bloodhound));
