(function ($, _) {
    "use strict";

    function ReportEditableCell(container, rowData, columnData) {
        this.container = container;
        this.caption = $('.shed-report__table-cell-caption', this.container);

        this.rowData = rowData;
        this.columnData = columnData;
    }

    ReportEditableCell.prototype.initialize = function () {
        this.container.addClass('shed-report__table-cell--editable');
        if (this.caption.text() === '') {
            this.caption.text('not set');
        }

        this.caption.click(_.bind(this.showEditControls, this));
    };

    ReportEditableCell.prototype.showEditControls = function () {
        this.editControls = $('<div class="shed-report__table-cell-edit-controls">' +
            '<input type="text" value="" placeholder="' + this.columnData.placeholder + '">' +
            '<button type="button" class="shed-report__table-cell-edit-controls-save btn btn-default btn-xs">' +
            '<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>' +
            '</button>' +
            '<button type="button" class="shed-report__table-cell-edit-controls-cancel btn btn-default btn-xs">' +
            '<span class="glyphicon glyphicon-remove" aria-hidden="true"></span>' +
            '</button>' +
            '<p class="shed-report__table-cell-edit-controls-error">' + this.columnData.formatErrorMessage + '</p>' +
            '</div>');

        this.input = $('input', this.editControls);
        this.formatErrorMessage = $('.shed-report__table-cell-edit-controls-error', this.editControls);
        this.saveButton = $('.shed-report__table-cell-edit-controls-save', this.editControls);
        this.cancelButton = $('.shed-report__table-cell-edit-controls-cancel', this.editControls);

        var captionText = this.caption.text().trim();
        if (captionText !== 'not set') {
            this.input.val(captionText);
        }

        this.caption.hide();

        this.caption.after(this.editControls);
        this.attachEditControlsListeners();

        this.input.focus();
    };

    ReportEditableCell.prototype.hideEditControls = function () {
        this.editControls.hide();
        this.caption.show();
    };

    ReportEditableCell.prototype.attachEditControlsListeners = function () {
        this.cancelButton.click(_.bind(this.hideEditControls, this));
        this.saveButton.click(_.bind(this.save, this));
        this.input.keypress(_.bind(function (e) {
            this.formatErrorMessage.hide();
            if (e.which === 13) {
                this.save();
            }
        }, this));
    };

    ReportEditableCell.prototype.save = function () {
        var value = this.input.val();
        var regex = new RegExp(this.columnData.format);

        if (!regex.test(value)) {
            this.formatErrorMessage.show();

            return;
        }

        var url = this.columnData.url;
        var match, loop = true;
        var re = /_row-([a-zA-Z]*)_/g;

        while (loop) {
            match = re.exec(url);
            if (match !== null) {
                url = url.replace(match[0], this.rowData[match[1]]);
            } else {
                loop = false;
            }
        }

        var postData = {};
        postData[this.columnData.paramName] = value;

        $.post(url, postData).done(_.bind(function () {
            this.caption.text(value);
            this.hideEditControls();
        }, this));
    };

    function Report(container) {
        this.container = container;
        this.editableColumns = this.findEditableColumns();
    }

    Report.prototype.findEditableColumns = function () {
        var columns = {};
        $('.shed-report__table-column--editable').each(function () {
            var th = $(this);
            columns[th.index()] = th;
        });

        return columns;
    };

    Report.prototype.initialize = function () {
        this.initializeEditor();
        this.initializeFilters();

        var updateInterval = 1000 * 60 * 10; // 10 minutes
        setInterval(_.bind(this.reload, this), updateInterval);
    };

    Report.prototype.initializeFilters = function () {
        $('.shed-report__controls-filter', this.container).each(_.bind(function (key, filterHtml) {
            /*jslint unparam: true, node: true */

            var filter = $(filterHtml);

            var toggleBy = filter.data('toggle-by'),
                toggleDisplayWhenValueIs = filter.data('toggle-display-when-value-is');

            if (toggleBy && toggleDisplayWhenValueIs) {
                var toggleByElement = $('[name="filters[' + toggleBy + ']"]', this.container);

                if (toggleByElement.val() === toggleDisplayWhenValueIs) {
                    filter.show();
                } else {
                    filter.hide();
                }

                toggleByElement.change(function (event) {
                    if (event.target.value === toggleDisplayWhenValueIs) {
                        filter.show();
                    } else {
                        filter.hide();
                    }
                });
            }
        }, this));
    };

    Report.prototype.initializeEditor = function () {
        _.forOwn(this.editableColumns, _.bind(function (column, columnIndex) {
            $('tbody tr td:nth-child(' + (window.parseInt(columnIndex) + 1) + ')', this.container).each(_.bind(function (tdKey, tdHtml) {

                /*jslint unparam: true, node: true */
                var td = $(tdHtml);

                var cell = new ReportEditableCell(td, td.parent().data(), column.data());
                cell.initialize();
            }, this));
        }, this));
    };

    Report.prototype.reload = function () {
        var tableContainer = $('.shed-report__data', this.container);

        tableContainer.isLoading({
            text:     "Refreshing data...",
            position: "overlay",
            tpl:      '<span class="isloading-wrapper %wrapper%">%text%<i class="%class% glyphicon glyphicon-refresh spin"></i></span>'
        });

        $.get(tableContainer.data('url'), {}, _.bind(function (data) {
            tableContainer.html(data);
            tableContainer.isLoading('hide');
            this.initializeEditor();
        }, this));
    };

    $(function () {
        var reportContainer = $('.shed-report');
        if (reportContainer.length > 0) {
            var report = new Report(reportContainer);
            report.initialize();
        }

        var topPerformingRevenueTypeIdElement = $('.j-shed-report-top-performing__revenue-type-id');
        topPerformingRevenueTypeIdElement.change(function (e) {
            var revenueTypeId = e.target.value;
            var url = e.target.dataset.customersByRevenueTypeUrl;
            var customerSelect = $('.j-shed-report-top-performing__customer-id');

            var dateRangeInputs = $('.j-shed-report-top-performing__date-range input');
            var dateRangeStart = dateRangeInputs[0].value;
            var dateRangeEnd = dateRangeInputs[1].value;

            $.get(
                url,
                {
                    revenueTypeId:  revenueTypeId,
                    dateRangeStart: dateRangeStart,
                    dateRangeEnd:   dateRangeEnd
                },
                function (data) {
                    customerSelect.empty();
                    customerSelect.append($('<option>').text("Customer"));

                    data.forEach(function (option) {
                        customerSelect.append($('<option>').text(option.name).attr('value', option.id));
                    });
                }
            );
        });

        var directorsRevenueRevenueCodeIdElement = $('.j-shed-report-directors-revenue__revenue-code-id');
        directorsRevenueRevenueCodeIdElement.change(function (e) {
            window.location.href = window.location.pathname + '?filters%5BrevenueCodeId%5D=' + e.target.value;

            $(e.target).closest('.shed-report__controls-filters').addClass('shed-report__controls-filters--overlaid');
        });

        var newReleaseReport = $('.shed-report--NewRelease');
        if (newReleaseReport.length) {
            var btn = $('<a href="#" class="btn btn-sm btn-default">Generate Barcodes</a>');
            btn.on('click', function () {
                var zip = new JSZip();
                var dir = zip.folder("barcodes");

                $('tbody tr', newReleaseReport).each(function (i, row) {
                    var title = $('.shed-report--NewRelease__cell-title .shed-report__table-cell-caption', row).text().trim();
                    var upc = $('.shed-report--NewRelease__cell-upc .shed-report__table-cell-caption', row).text();
                    addMovie(title, upc);
                });

                function addMovie(title, upc) {
                    var id = 'shed-generate-barcodes';
                    var svg = getSvg(id);

                    try {
                        JsBarcode('#' + id, upc, {
                            format:     'upc',
                            textMargin: 0,
                            background: '',
                            font:       'arial'
                        });

                        var fileName = title + ' - ' + upc + ".svg";
                        fileName = fileName.replace(/[<>:"\/\\|?*]+/g, '');

                        dir.file(fileName, svg.outerHTML);
                    } catch (e) {
                        console.log('Failed to add barcode:', e);
                    }
                }

                zip.generateAsync({type: "blob"})
                    .then(function (content) {
                        // see FileSaver.js
                        saveAs(content, "barcodes.zip");
                    });

                function getSvg(id) {
                    var svg = $('#' + id);
                    if (!svg.length) {
                        svg = $('<svg style="display: none" id="' + id + '"></svg>');
                        $('body').append(svg);
                    }

                    return svg[0];
                }
            });


            $('.shed-report__controls-nav', newReleaseReport).append(btn);
        }
    });

}($, _));
