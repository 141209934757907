(function ($, _, Bloodhound) {
    'use strict';

    var form = $('.shed-vod-scheduling-manual-entry-form');
    if (form.length === 0) {
        return;
    }

    var log = $('.shed-vod-scheduling-manual-entry-log');
    var titleInput = $('[name="title"]', form);

    var broadcastVersions = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote:         {
            url:      titleInput.data('url') + '?query=%QUERY',
            wildcard: '%QUERY'
        }
    });

    broadcastVersions.initialize();

    titleInput.typeahead(null, {
        display:   function (searchObject) {
            return searchObject.title + ' (BV / ' + searchObject.editLevel + ' / ' + searchObject.language + ')';
        },
        highlight: true,
        source:    broadcastVersions,
        limit:     25
    }).bind("typeahead:selected", function (obj, data) {
        titleInput.typeahead('val', '[' + data.id + '] ' + titleInput.typeahead('val'));

        return false;
    });

    function showError(error) {
        var errorContainer = $('.shed-vod-scheduling-manual-entry-error');
        errorContainer.text(error).show();
        setTimeout(function () {
            errorContainer.hide();
        }, 5000);
    }

    function addLogEntry(data) {
        $('.shed-vod-scheduling-manual-entry-log').append('<li><strong>Customer:</strong> ' + $('select[name="customerId"] option:selected').text() + ', <strong>Title:</strong> ' + $('[name="title"]', form).val() + ', <strong>From:</strong> ' + $('[name="dateStart"]', form).val() + ', <strong>To:</strong> ' + $('[name="dateEnd"]', form).val() + '</li>');
    }

    form.submit(function (event) {
        event.preventDefault();

        var rx = /\[(\d+)\]/;
        var res = rx.exec($('[name="title"]', form).val());
        if (!res || !res[1]) {
            showError('Select some BV title');
            return false;
        }

        var data = {
            customerId: $('[name="customerId"]', form).val(),
            bvId:       res[1],
            fromDate:   $('[name="dateStart"]', form).val(),
            toDate:     $('[name="dateEnd"]', form).val()
        };

        $.ajax({
            method: 'post',
            url:    form[0].action,
            data:   data
        }).done(function (response) {
            if (response.status === 'success') {
                addLogEntry(data);

                titleInput.typeahead('val', '');
                $('[name="dateStart"]', form).val('');
                $('[name="dateEnd"]', form).val('');

                $('[name="customerId"]', form).focus();
            } else {
                showError(response.message);
            }
        });
    });

}($, _, window.Bloodhound));
