(function (window, $) {
    "use strict";

    function CostData(container) {
        this.container = container;
        this.modal = $('.modal', this.container);
    }

    CostData.prototype.initialize = function () {
        this.container.on('click', '.shed-revenue-cost-data__show-form-trigger', _.bind(function (event) {
            var url = $(event.target).closest('.shed-revenue-cost-data__show-form-trigger').data('url');

            this.showForm(url);

            return false;
        }, this));

        this.container.on('click', '.shed-revenue-cost-data__delete-trigger', _.bind(function (event) {
            if (!window.confirm("Are you sure you want to delete expense?")) {
                return false;
            }

            var url = $(event.target).closest('.shed-revenue-cost-data__delete-trigger').data('url');

            this.deleteExpense(url);

            return false;
        }, this));

        this.container.on('submit', 'form[name="cost_data"]', _.bind(function (event) {
            event.preventDefault();

            var form = $(event.target);

            this.saveExpense(form);
        }, this));
    };

    CostData.prototype.showForm = function (url) {
        $.ajax({
            type: 'get',
            url:  url
        }).done(_.bind(function (response) {
            $('.modal-body', this.modal).html(response.form);
            this.modal.modal();
        }, this));
    };

    CostData.prototype.saveExpense = function (form) {
        $.ajax({
            type: form.attr('method'),
            url:  form.attr('action'),
            data: form.serialize()
        }).done(_.bind(function () {
            this.modal.modal('hide');
            this.refreshExpenses();
        }, this)).fail(_.bind(function (response, textStatus, errorThrown) {
            /*jslint unparam: true, node: true */
            response.responseJSON = response.responseJSON || undefined;
            if (response.responseJSON !== undefined) {
                if (response.responseJSON.hasOwnProperty('form')) {
                    $('form', this.modal).replaceWith(response.responseJSON.form);
                } else {
                    window.alert(errorThrown);
                }
            }
        }, this));
    };

    CostData.prototype.refreshExpenses = function () {
        var table = $('.shed-revenue-cost-data__expenses', this.container),
            url = table.data('url');

        $.ajax({
            type: 'get',
            url:  url
        }).done(function (response) {
            table.replaceWith(response);
        });
    };

    CostData.prototype.deleteExpense = function (url) {
        $.ajax({
            type: 'post',
            url:  url
        }).done(_.bind(function () {
            this.refreshExpenses();
        }, this));
    };


    function DvdExpenseEntry(container) {
        this.container = container;
        this.costTypeSelect = $('.shed-cost-data-dvd-expense-form__cost-type', this.container);
        this.hideAmountCostTypeIds = this.costTypeSelect.data('hide-amount-ids').toString().split(',');
        this.hideQuantityCostTypeIds = this.costTypeSelect.data('hide-quantity-ids').toString().split(',');
    }

    DvdExpenseEntry.prototype.initialize = function () {
        this.costTypeSelect.change(_.bind(this.updateAmountAndQuantityVisibility, this));
        this.updateAmountAndQuantityVisibility();
    };

    DvdExpenseEntry.prototype.updateAmountAndQuantityVisibility = function () {
        var costTypeOption = this.costTypeSelect.val();

        var amount = $('.shed-cost-data-dvd-expense-form__amount'),
            amountFormGroup = amount.parents('.form-group');

        if (this.hideAmountCostTypeIds.indexOf(costTypeOption) !== -1) {
            amountFormGroup.hide();
            amount.removeAttr('required');
        } else {
            amountFormGroup.show();
            amount.prop('required', true);
        }

        var quantity = $('.shed-cost-data-dvd-expense-form__quantity'),
            quantityFormGroup = quantity.parents('.form-group');

        if (this.hideQuantityCostTypeIds.indexOf(costTypeOption) !== -1) {
            quantityFormGroup.hide();
            quantity.removeAttr('required');
        } else {
            quantityFormGroup.show();
            quantity.prop('required', true);
        }
    };

    $(function () {
        var costDataContainer = $('.shed-revenue-cost-data');
        if (costDataContainer.length > 0) {
            var costData = new CostData(costDataContainer, window);
            costData.initialize();
        }

        var dvdExpenseContainer = $('form.shed-cost-data-dvd-expense-form');
        if (dvdExpenseContainer.length > 0) {
            var dvdExpenseEntry = new DvdExpenseEntry(dvdExpenseContainer);
            dvdExpenseEntry.initialize();
        }

        // Quantity field alert
        var costDataQuantityField = $('#dvd_expense_cost_data_quantity'),
            costTypeSelector = $('#dvd_expense_cost_data_costType'),
            costDataDVDPackagingMaxValue = 1000,
            costDataOtherDVDMaxValue = 2500,
            costTypeDVDReplication = 9,
            costTypeDVDPackaging = 11,
            costTypeDVDInserts = 12,
            notifyList = [];

        function quantityAlertPrepare(costTypeValue,
                                      costTypeDVDValue,
                                      inputValue,
                                      maxValue) {
            if (costTypeValue === costTypeDVDValue &&
                inputValue > maxValue &&
                notifyList.indexOf(costTypeValue) === -1) {
                alert("You entered " + inputValue + ". Are you really sure? \nThat's a lot of DVDs.");

                notifyList.push(costTypeValue);
            }
        }

        function quantityAlert() {
            var fieldValue = costDataQuantityField.val(),
                costTypeValue = parseInt(costTypeSelector.val());

            quantityAlertPrepare(costTypeValue,
                costTypeDVDPackaging,
                fieldValue,
                costDataDVDPackagingMaxValue);

            quantityAlertPrepare(costTypeValue,
                costTypeDVDReplication,
                fieldValue,
                costDataOtherDVDMaxValue);

            quantityAlertPrepare(costTypeValue,
                costTypeDVDInserts,
                fieldValue,
                costDataOtherDVDMaxValue);
        }

        if (costDataQuantityField.length > 0) {
            costDataQuantityField.blur(function () {
                quantityAlert();
            });

            costTypeSelector.on('change', function () {
                quantityAlert();
            });
        }
    });

}(window, $));
